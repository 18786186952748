<div fxLayout="row">
	<div fxFlex="5">
		<a mat-raised-button color="primary" style="margin-top: 10px; margin-left: 5px;" (click)="back()"><< Back</a>
	</div>
	<div fxFlex="95"> 
		<div fxLayout="col" fxLayoutAlign="center top">
			<div fxFlex="50">		
				<mat-card style="width:40vw; margin-top: 10px;">
					<mat-card-header>
					    <h2>Ticket Detail</h2>
					</mat-card-header>
					<mat-card-content>
						<form [formGroup]="editTicketForm" class="edit-ticket-form" (ngSubmit)="postEditFormSubmit(editTicketForm)">
						<p>
							<mat-form-field appearance="fill" style="width:40vw">
							  <mat-label>Ticket Description</mat-label>
							  <input matInput formControlName="ticketDescription">
							</mat-form-field>
					    </p>

					    <p>
							<mat-form-field appearance="fill" style="width:40vw">
							  <mat-label>Flat Number</mat-label>
							  <input matInput formControlName="flatNumber">
							</mat-form-field>
					    </p>

					    <p>
							<mat-form-field appearance="fill" style="width:40vw">
							  <mat-label>Mobile Number</mat-label>
							  <input matInput formControlName="mobileNumber">
							</mat-form-field>
					    </p>

					    <p>
						<mat-form-field appearance="fill" style="width:40vw">
						  <mat-label>Select Type</mat-label>
						  <mat-select formControlName="ticketType">
						    <mat-option *ngFor="let ticketType of allTicketType" [value]="ticketType.id">{{ticketType.ticket_type}}</mat-option>
						  </mat-select>
						</mat-form-field>
						</p>

						<p>
						<mat-form-field appearance="fill" style="width:40vw">
						  <mat-label> Status </mat-label>
						  <mat-select formControlName="ticketStatus">
						    <mat-option *ngFor="let ticketStatusDetail of allTicketStatus" [value]="ticketStatusDetail.id">{{ticketStatusDetail.status}}</mat-option>
						  </mat-select>
						</mat-form-field>
						</p>

						<p>
							<mat-form-field appearance="fill" style="width:40vw">
							  <mat-label>Assigned To</mat-label>
							  <input matInput readonly formControlName="assignedTo">
							</mat-form-field>
					    </p>

					    <p *ngIf="isAdmin()">
							<mat-form-field appearance="fill" style="width:40vw">
							  <mat-label>Expenditure Amount</mat-label>
							  <input type="number" matInput formControlName="expenditure_amount">
							</mat-form-field>
					    </p>

					   <!--  <p>			
						  <strong>Reported By: &nbsp;</strong>
						  <span>{{ticketDetail.reported_by.username}}</span>			  
					    </p>

						<p>			
						  <strong>Created On: &nbsp;</strong>
						  <span>{{ticketDetail.created_at  | date:'medium'}}</span>			  
					    </p>

					    <p>			
						  <strong>Updated On: &nbsp;</strong>
						  <span>{{ticketDetail.updated_at  | date:'medium'}}</span>		
					    </p>	     -->
					   
					    <p>
							<button mat-raised-button color="primary">Submit</button>
					    </p>
					</form>
					</mat-card-content>
				</mat-card>
				<br/>
			</div>

			<div fxFlex="45">		
				<mat-card style="width:40vw; margin-top: 10px;">
					<mat-card-header>
					    <h2>Attachment(s)</h2>
					</mat-card-header>
					<mat-card-content>	
						<div style="text-align: right;">
							<form [formGroup]="addAttachmentForm" (ngSubmit)="onSubmitFileUpload()">
								<input type="file" name="ticketAttachment" (change)="onChange($event)" />
								<button mat-raised-button color="primary" type="submit">Add Attachment</button>
							</form>
					    </div>
					    <div id="attachmentListSection">
						    <mat-list role="list" *ngIf="ticketAttachments.length > 0">
							  <mat-list-item *ngFor="let attachment of ticketAttachments" role="listitem">
							  	<div fxFlex="80">
							  		<a class="button-icon-link" href="{{attachment.file}}" target="__blank">{{attachment.description}}</a> 
							  	</div>
							  	<div fxFlex="20">
							  		<mat-icon title="Open attachment" class='action-icon' (click)="openAttachment(attachment.file)">open_in_new</mat-icon>
							  		&nbsp;
								  	<mat-icon title="Delete Attachment" class='action-icon' (click)="deleteAttachment(attachment.id)">delete</mat-icon>
								 </div>
							  </mat-list-item>		  
							</mat-list>
							<mat-list role="list" *ngIf="ticketAttachments.length == 0">No attachment(s)</mat-list>
						</div>
					</mat-card-content>
				</mat-card>
				<br/>
				<mat-card style="width:40vw">
					<mat-card-header>
					    <h2>Comment(s)</h2>
					</mat-card-header>
					<mat-card-content>	
						<form [formGroup]="addCommentForm" class="add-comment-form" (ngSubmit)="postTicketComment(addCommentForm)">
							<div style="display: inline-block;">
								<mat-form-field appearance="fill" style="width:30vw">
								  <mat-label>Add comment here</mat-label>
								  <textarea matInput formControlName="comment"></textarea>
								</mat-form-field>							
							</div>
							<div style="float: right;">
								<button mat-raised-button color="primary" [disabled]="!addCommentForm.valid">Add Comments</button>
						    </div>
						</form>
					    
						<div id="commentSection" *ngIf="ticketComments.length > 0">					
						  <mat-card *ngFor="let commentDetail of ticketComments">
						  	<div>
						  		<div fxFlex="5" style="background-color: #cd74dc63; font-style: italic; font-weight: bold; padding: 5px 0px 4px 2px;"> <mat-icon> account_circle </mat-icon></div>
							  	<div fxFlex="85" style="background-color: #cd74dc63; font-style: italic; font-weight: bold; padding: 5px;"> {{formatAddedBy(commentDetail.added_by.username)}} commented on {{commentDetail.created_at | date:'medium'}}</div>
							  	<div fxFlex="10" style="background-color: #cd74dc63; font-style: italic; font-weight: bold; padding: 5px;"><mat-icon class='action-icon' title="Delete Comment" (click)="deleteComment(commentDetail.id)">delete</mat-icon>
							  	</div>
							 </div>
							<mat-card-content>				
						    	{{commentDetail.comment}}
						   </mat-card-content>
						  </mat-card>
						</div>
						<mat-card *ngIf="ticketComments.length == 0">
							No comment(s) available.
						</mat-card>

					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
</div>

