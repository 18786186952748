<div>
   <mat-card id="loginMatCard">
   <!--  <mat-card-content> -->
      <form fxLayout="column" [formGroup]="form" (ngSubmit)="onSubmit()">
        <h2>Log In</h2>
        <mat-error *ngIf="loginInvalid">
          The username and password were not recognized
        </mat-error>
        <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput placeholder="User Name" formControlName="username" required autocomplete="off">
            <mat-icon matSuffix>person_outline</mat-icon>
            <mat-error>
              Please provide a valid email address
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput type="password" placeholder="Password" formControlName="password" required autocomplete="off">
            <mat-icon matSuffix>lock</mat-icon>
            <mat-error>
              Please provide a valid password
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <button mat-raised-button color="primary">Login</button>
        </div>
      </form>
    <!-- </mat-card-content> -->
  </mat-card>
</div>
