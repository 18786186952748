import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ProfileComponent } from './profile/profile.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { AuthGuard } from './shared/auth.service';

const routes: Routes = [
   { path: '', component: HomeComponent },
   { path: 'login', component: LoginComponent },
   { path: 'registration', component: RegistrationComponent },
   { path: 'verify-user', component: VerifyUserComponent },
   { path: 'about-us', component: AboutUsComponent },   
   { path: 'gallery', component: GalleryComponent, canActivate: [AuthGuard] },
   { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
   // { path: 'contact-us', component: ContactComponent },
   { path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard] },
   { path: 'tickets/:id', component: TicketDetailComponent, canActivate: [AuthGuard] }   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
