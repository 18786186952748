import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { RestApiService } from "../shared/rest-api.service";

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
 
  galleryImageList: any = [];
  galleryIdList: Array <any> = [];
  imagesByGalleryId: Array <any> = [];

  constructor(private _lightbox: Lightbox, public restApi: RestApiService) { 
  	//
  }

  ngOnInit(): void {
  	this.getGalleryImagesList();
  }

  getGalleryImagesList() {
    return this.restApi.getGalleryImageList().subscribe((data: {}) => {
      this.galleryImageList = data;
      for (let i = 0; i < this.galleryImageList.length; i++) {        
        const src = this.galleryImageList[i].gallery_image;
        const caption = 'Image ' + i + ' caption here';
        const thumb = this.galleryImageList[i].thumbnail;
        const albumDetail = {
           src: src,
           caption: caption,
           thumb: thumb,
           gallery: this.galleryImageList[i].gallery
        };

        if(typeof(this.galleryImageList[i].gallery.id) != 'undefined'){
          let galleryId = this.galleryImageList[i].gallery.id;
          if(!this.galleryIdList.includes(galleryId)){
            this.galleryIdList.push(galleryId);
            this.imagesByGalleryId[galleryId] = [];
          }

          this.imagesByGalleryId[galleryId].push(albumDetail);
        }
      }

      /*console.log("galleryIdList ===>", this.galleryIdList);
      console.log("imagesByGalleryId ===>", this.imagesByGalleryId);*/

    })  

  }

  open(index: number, galleryId: number): void {
    // open lightbox
    this._lightbox.open(this.imagesByGalleryId[galleryId], index, {showImageNumberLabel:true, showZoom:true, showRotate:true, fitImageInViewPort:true});
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
