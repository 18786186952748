import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Notice } from '../shared/notice';
import { News } from '../shared/news';
import { CommiteeMember } from '../shared/commitee-member';
import { HelpfullInfoList } from '../shared/helpfull-info-list';
import { SlidingImages } from '../shared/sliding-images';
import { GalleryImages } from '../shared/gallery-images';
import { ImportantLinks } from '../shared/important-links';
import { Tickets } from '../shared/tickets';
import { TicketType } from '../shared/ticket-type';
import { TicketStatus } from '../shared/ticket-status';
import { TicketAttachment } from '../shared/ticket-attachments';
import { User } from '../shared/user';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  // Define API
  apiURL = 'http://yoginathjorwar.com/backend';
  //apiURL = 'http://localhost:8001';
  data: any;

  constructor(private http: HttpClient) { }

  /*========================================
    CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
 
  // Error handling 
  handleError(error) {
     if(typeof(error.error) != 'undefined' && typeof(error.error.detail) != 'undefined'){
       let errorDetail = (error.error.detail).toLowerCase();
       if(errorDetail.includes('signature')){
         localStorage.clear();
         window.location.reload(true);
       }
     }

     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     //window.alert(errorMessage);
     return throwError(errorMessage);
  }

  /*********************   USER   ****/
  //User registration
  // HttpClient API post() method => Create user account API service
  registerUser(requestData) {
    let userProfileUrl = "/accounts/register/?format=json";    
    return this.http.post<any>(this.apiURL + userProfileUrl, requestData, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // HttpClient API post() method => verify registration API service
  verifyRegistration(requestData) {
    let verifyRegistrationUrl = "/accounts/verify-registration/";    
    return this.http.post<any>(this.apiURL + verifyRegistrationUrl, requestData, this.httpOptions)
    .pipe(
      /*retry(2),*/
      catchError(this.handleError)
    )
  }

  // HttpClient API get() method => Fetch user profile
  getUserProfile(): Observable<User> {
    return this.http.get<User>(this.apiURL + '/accounts/profile/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  updateProfile(formData, id){
   return this.http.put<any>(this.apiURL + '/accounts/tickets/'+id+'/', formData, this.httpOptions)     
  }

  // HttpClient API get() method => Fetch notice list
  getNoticeList(): Observable<Notice> {
    return this.http.get<Notice>(this.apiURL + '/home/notices/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // HttpClient API get() method => Fetch news list
  getNewsList(): Observable<News> {
    return this.http.get<News>(this.apiURL + '/home/news/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

    // HttpClient API get() method => Fetch Commitee Member List
  getCommiteeMemberList(): Observable<CommiteeMember> {
    return this.http.get<CommiteeMember>(this.apiURL + '/home/commiteemembers/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

    // HttpClient API get() method => Fetch Helpfull Info list
  getHelpfullInfoList(): Observable<HelpfullInfoList> {
    return this.http.get<HelpfullInfoList>(this.apiURL + '/home/helpfull_info_list/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

    // HttpClient API get() method => Fetch Sliding Images list
  getSlidingImageList(): Observable<SlidingImages> {
    return this.http.get<SlidingImages>(this.apiURL + '/home/sliding_images/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // HttpClient API get() method => Fetch Gallery Images list
  getGalleryImageList(): Observable<GalleryImages> {
    return this.http.get<GalleryImages>(this.apiURL + '/home/gallery_images_list/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // HttpClient API get() method => Fetch Important link list
  getImportantLinksList(): Observable<ImportantLinks> {
    return this.http.get<ImportantLinks>(this.apiURL + '/home/important_links/?format=json', this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }


  //Ticket management services
  // HttpClient API get() method => Fetch Ticket list
  getTicketsList(ticketId): Observable<Tickets> {
    let username = localStorage.getItem('username');
    let ticketApiUrl = "/ticket_management/tickets/?format=json&user="+username;
    if(typeof(ticketId) != 'undefined' && ticketId != -1){
      ticketApiUrl = "/ticket_management/tickets/"+ ticketId +"/?format=json";
    }    

    return this.http.get<Tickets>(this.apiURL + ticketApiUrl, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }


  // HttpClient API get() method => Fetch Ticket Type list
  getTicketTypeList(): Observable<TicketType> {
    let ticketTypeApiUrl = "/ticket_management/ticket_types/?format=json";    

    return this.http.get<TicketType>(this.apiURL + ticketTypeApiUrl, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // HttpClient API get() method => Fetch Ticket Status list
  getTicketStatusList(): Observable<TicketStatus> {
    let ticketStatusApiUrl = "/ticket_management/ticket_status/?format=json";    

    return this.http.get<TicketStatus>(this.apiURL + ticketStatusApiUrl, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // HttpClient API get() method => Fetch Ticket Attachment list
  getTicketAttachmentList(): Observable<TicketAttachment> {
    let ticketAttachmentApiUrl = "/ticket_management/ticket_attachments/?format=json";    

    return this.http.get<TicketAttachment>(this.apiURL + ticketAttachmentApiUrl, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // HttpClient API post() method => Create ticket API service
  createTicket(requestData) {
    let head = new Headers({ 'Content-Type': 'application/json' });
    let ticketCreateApiUrl = "/ticket_management/tickets/?format=json";    
    return this.http.post(this.apiURL + ticketCreateApiUrl, requestData, this.httpOptions);
  }

  deleteTicket(id){
    return this.http.delete(this.apiURL + '/ticket_management/tickets/' + id);            
  }

  // HttpClient API post() method => Add ticket comment API service
  addTicketComment(data) {
    let head = new Headers({ 'Content-Type': 'application/json' });
    let ticketCommentCreateApiUrl = "/ticket_management/ticket_comments/?format=json";
    return this.http.post(this.apiURL + ticketCommentCreateApiUrl, data, this.httpOptions);
  }

  deleteComment(id){
    return this.http.delete(this.apiURL + '/ticket_management/ticket_comments/' + id);            
  }

  deleteTicketAttachment(id){
    return this.http.delete(this.apiURL + '/ticket_management/ticket_attachments/' + id);            
  }

  updateTicket(formData, id){
   return this.http.put<any>(this.apiURL + '/ticket_management/tickets/'+id+'/', formData, this.httpOptions)     
  }

  uploadTicketAttachment(formData) {
    return this.http.post<any>(this.apiURL + '/ticket_management/ticket_attachments/ ', formData);
  }
}
