import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormsModule, NgForm } from '@angular/forms'; 
import { RestApiService } from "../shared/rest-api.service";
import { Router } from '@angular/router';

export interface DialogData {
  allTicketType: any;  
}

@Component({
  selector: 'app-add-ticket-dialog',
  templateUrl: './add-ticket-dialog.component.html',
  styleUrls: ['./add-ticket-dialog.component.css']
})
export class AddTicketDialogComponent implements OnInit {
  addTicketForm: FormGroup;
  ticketDescription: string = ''; 
  ticketType: string = ''; 
  username: string = '';
  constructor(
    public restApi: RestApiService,
  	public dialogRef: MatDialogRef<AddTicketDialogComponent>,
  	@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private router: Router
  	) { 

    this.username = localStorage.getItem('username');

    // To initialize FormGroup  
    this.addTicketForm = fb.group({  
      'ticketDescription' : [null, Validators.required],  
      'ticketType' : [null, Validators.required],
      'flatNumber' : [null, Validators.required],
      'mobileNumber' : [null, Validators.required]
    });
  }

  ngOnInit(): void {
  	//
  }

  onFormSubmit(formData){
    console.log("form ==> ", formData.value.ticketDescription);
    let requestDataForAddTicket = {
        description: typeof(formData.value.ticketDescription) != 'undefined' ? formData.value.ticketDescription : '',
        ticket_type: typeof(formData.value.ticketType) != 'undefined' ? formData.value.ticketType : '',
        flat_number: typeof(formData.value.flatNumber) != 'undefined' ? formData.value.flatNumber : '',
        mobile_number: typeof(formData.value.mobileNumber) != 'undefined' ? formData.value.mobileNumber : '',
        reported_by: this.username,  
        ticket_status: 1        
      };

    return this.restApi.createTicket(JSON.stringify(requestDataForAddTicket)).subscribe((data: any) => {      
      if(typeof(data) != 'undefined' && typeof(data.id) != 'undefined' ){
        let detailRoute = '/tickets/'+ data.id;
        this.dialogRef.close();
        this.router.navigate([detailRoute]);
      }
    })  
  } 

  close() {
    this.dialogRef.close();
  }

}
