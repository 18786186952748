 <h2 align="center">Account Registration</h2>
 <div fxLayout="row" fxLayoutAlign="center">
	 <mat-card>
	 	<form [formGroup]="registrationForm" class="registration-form" (ngSubmit)="onFormSubmit(registrationForm)">	
		 <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center top" fxLayoutGap="10px"> 	
			<div fxLayout="column" fxLayout.lt-md="row"  fxLayoutGap="10px" class="container">
				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>User Name</mat-label>
					<input matInput formControlName="username" autocomplete="off" tabindex="1">
					<mat-hint>Please enter User Name.</mat-hint>
					</mat-form-field>
				</div>	

				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>First Name</mat-label>
					<input matInput placeholder="Ex.Santosh" formControlName="first_name" tabindex="3">
					<mat-hint>Please enter your First Name.</mat-hint>
					</mat-form-field>
				</div>

				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>Password</mat-label>
					<input type="password" matInput formControlName="password" tabindex="5">
					<mat-hint>Please enter your Password.</mat-hint>
					</mat-form-field>
				</div>

				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>Flat Number</mat-label>
					<input matInput placeholder="A-901" formControlName="flat_number" tabindex="7">
					<mat-hint>Please enter your Flat Number.</mat-hint>
					</mat-form-field>
				</div>
			</div>
			<div fxLayout="column" fxLayout.lt-md="row"   fxLayoutGap="10px" class="container">
				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>Email</mat-label>
					<input matInput placeholder="ex. test@gmail.com" formControlName="email" tabindex="2">
					<mat-hint>Please enter your Email.</mat-hint>
					</mat-form-field>			
				</div>
				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>Last Name</mat-label>
					<input matInput placeholder="ex.Naik" formControlName="last_name" tabindex="4">
					<mat-hint>Please enter your Last Name.</mat-hint>
					</mat-form-field>
				</div>
				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>Confirm Password</mat-label>
					<input type="password" matInput formControlName="confirm_password" tabindex="6">
					<mat-hint>Please confirm your Password.</mat-hint>
					</mat-form-field>
				</div>

				<div class="registration-fields" fxLayoutAlign="center center">
					<mat-form-field appearance="standard">
					<mat-label>Contact Number</mat-label>
					<input matInput formControlName="contact_number" tabindex="8">
					<mat-hint>Please enter Mobile Number.</mat-hint>
					</mat-form-field>
				</div>
			</div>	
		 </div>

		 <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center right">
			<button mat-raised-button mat-primary color="primary" [disabled]="!registrationForm.valid" tabindex="7">Submit</button>
		</div>

		</form>
	 </mat-card>
</div>