import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestApiService } from "../shared/rest-api.service";
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {
  userId: any
  timestamp: any
  signature: any
  constructor(
  	private route: ActivatedRoute,
  	public restApi: RestApiService,
  	private router: Router
  	) { }

  ngOnInit(): void {
  	this.route.queryParams
      .subscribe(params => {
        console.log(params); 
        this.userId = params.user_id;
        this.timestamp = params.timestamp;
        this.signature = params.signature;
        console.log("this.userId:- ", this.userId); 
        console.log("this.timestamp:- ", this.timestamp); 
        console.log("this.signature:- ", this.signature); 


        let requestDataForUserRegistration = {
	       user_id: this.userId,
	       timestamp: this.timestamp,
	       signature: this.signature
	     };

	    return this.restApi.verifyRegistration(JSON.stringify(requestDataForUserRegistration)).subscribe((data: any) => {
	      console.log("data ===> ", data);   
	      Swal.fire("User verification completed successfully !");
	      this.router.navigate(['/login']);	      
	    })  
      }
    );
  }

}
