<div id="profileSection">
	<mat-drawer-container class="example-container" [hasBackdrop]="false">
	  <mat-drawer opened #drawer [mode]="side">
	  	<mat-icon (click)="drawer.toggle()" style="float:right;">close</mat-icon>
	  	<h2>User Profile</h2>

		<div class="card">
			<img src="/assets/img/large-profile-icon.png" alt="John" style="width:91%">
			<h1>{{firstName}}&nbsp;{{lastName}}</h1>
			<p style="text-align:left;"><strong>Email:</strong> {{email}}</p>
			<p style="text-align:left;"><strong>Username:</strong> {{username}}</p><br/><br/><br/><br/><br/>	
		</div>

	  </mat-drawer>
	  <mat-drawer-content> <mat-icon (click)="drawer.toggle()" [ngClass]="showHideIcon(drawer)">menu</mat-icon>
	   <mat-tab-group dynamicHeight>		 
		  <mat-tab label="User Profile">
		    <div class="example-large-box mat-elevation-z4">
		      
		    	<div fxLayout="column" fxFlexFill fxLayoutAlign="top center">	
					<mat-card>
					<form [formGroup]="profileForm" class="profile-form" (ngSubmit)="onProfileFormSubmit(profileForm)">		
						<div fxFlex="40">
							<p>
								<mat-form-field appearance="standard" style="width:25vw">
								<mat-label>First Name</mat-label>
								<input matInput placeholder="Ex.Santosh" formControlName="first_name" tabindex="3">
								<mat-hint>Please enter your First Name.</mat-hint>
								</mat-form-field>
							</p>			

							<p>
								<mat-form-field appearance="standard" style="width:25vw">
								<mat-label>Flat Number</mat-label>
								<input matInput placeholder="A-901" formControlName="flat_number" tabindex="7">
								<mat-hint>Please enter your Flat Number.</mat-hint>
								</mat-form-field>
							</p> 
						</div>

						<div fxFlex="10">
						</div>

						<div fxFlex="40">			
							<p>
								<mat-form-field appearance="standard" style="width:25vw">
								<mat-label>Last Name</mat-label>
								<input matInput placeholder="ex.Naik" formControlName="last_name" tabindex="4">
								<mat-hint>Please enter your Last Name.</mat-hint>
								</mat-form-field>
							</p>			

							<p>
								<mat-form-field appearance="standard" style="width:25vw">
								<mat-label>Contact Number</mat-label>
								<input matInput formControlName="contact_number" tabindex="8">
								<mat-hint>Please enter Mobile Number.</mat-hint>
								</mat-form-field>
							</p>
						</div>
						<p>
							<button mat-raised-button mat-primary color="primary" type="submit" [disabled]="!profileForm.valid" tabindex="7">Submit</button>
						</p>
					</form>
					</mat-card>	
				</div>

		    </div>
		  </mat-tab>

		   <mat-tab label="Change Password">
		    <div class="example-large-box mat-elevation-z4">
		      
		      <div fxLayout="column" fxLayout.xs="row" fxFlexFill fxLayoutAlign="top center">	
					<mat-card>
						<form [formGroup]="passwordChangeForm" class="change-password-form" (ngSubmit)="onFormSubmit(passwordChangeForm)">		
							<div fxFlex="40" fxFlex.xs="100">
								<p>
									<mat-form-field appearance="standard" style="width:25vw">
									<mat-label>Old Password</mat-label>
									<input type="password" matInput formControlName="old_password" tabindex="1">
									<mat-hint>Please enter your Old Password.</mat-hint>
									</mat-form-field>
								</p>

								<p>
									<mat-form-field appearance="standard" style="width:25vw">
									<mat-label>New Password</mat-label>
									<input type="password" matInput formControlName="new_password" tabindex="2">
									<mat-hint>Please enter your New Password.</mat-hint>
									</mat-form-field>
								</p>

								

								
							</div>
							<div fxFlex="10" fxFlex.xs="100"></div>
							<div fxFlex="40" fxFlex.xs="100">
								<p>
									<mat-form-field appearance="standard" style="width:25vw">
									<mat-label>Confirm New Password</mat-label>
									<input type="password" matInput formControlName="confirm_new_password" tabindex="3">
									<mat-hint>Please enter your New Password Again.</mat-hint>
									</mat-form-field>
								</p>

								<p>
									<button mat-raised-button mat-primary color="primary" [disabled]="!passwordChangeForm.valid" tabindex="7">Change Password</button>
								</p>

								
							</div>			
							
						</form>
					</mat-card>	
				</div>

		    </div>
		  </mat-tab>

		</mat-tab-group>
	  </mat-drawer-content>
	</mat-drawer-container>
</div>