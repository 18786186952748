<div fxLayout="row wrap" fxLayout.xs="column">
	<div fxFlex="25" >
		<mat-card>
			<mat-card-title>
				Notice Board
			</mat-card-title>
			<mat-card-content style="height:116px; overflow: auto;" *ngIf="Notice.length > 0">
				<div *ngFor="let notice of Notice">
					<div style="clear:right;" fxLayout="row">
						<div style="display: inline-block;width:85vw;">
							<mat-icon style="font-size: 18px;">announcement</mat-icon> &nbsp; <span style="vertical-align: super;">{{notice.description}}</span>
						</div>
						<div *ngIf="notice.document" style="display: inline-block; margin-left: 20px; vertical-align: super; width:15vw;">						
							<a href="{{notice.document}}" target="_blank" title="Download"><img src="assets/img/pdf.svg" width=24 height=24></a>
						</div>
					</div>							
				</div>
			</mat-card-content>
			<mat-card-content style="height: 116px; overflow: auto;" *ngIf="Notice.length == 0">
				No Notice published yet.
			</mat-card-content>
		</mat-card>

		<mat-card style="margin-top: 1px;">
			<mat-card-title>
				Helpfull Contact
			</mat-card-title>
			<mat-card-content style="height:88px; overflow: auto;" *ngIf="HelpfullInfoList.length > 0">
				<div *ngFor="let helpfullInfo of HelpfullInfoList">
					<div>
						<mat-icon style="font-size: 18px;">phone</mat-icon> <span style="vertical-align: super;">{{helpfullInfo.description}}  : &nbsp;  {{helpfullInfo.contact}}</span> 
					</div>							
				</div>
			</mat-card-content>
			<mat-card-content style="height: 88px; overflow: auto;" *ngIf="HelpfullInfoList.length == 0">
				No Record.
			</mat-card-content>
		</mat-card>

		<mat-card style="margin-top: 1px;">
			<mat-card-title>
				Maintanance Payment Detail
			</mat-card-title>
			<mat-card-content style="height:148px;text-align: center;">
				<img src="assets/img/qrCode.png" width="151" height="151">
			</mat-card-content>
		</mat-card>

	</div>
	<div fxFlex="40">
		<div fxLayout="column">
			<div fxFlex="50" id="homeImageSlider">
				<ng-image-slider 
					[images]="imageObject"			
					[infinite]="true"
					[imagePopup]="true"
					[autoSlide]="1"
					[paginationShow]="true"
					[imageSize]="imageSizeObj"
					[manageImageRatio]="true"
					#nav	
				></ng-image-slider>
			</div>

			<div fxFlex="50">
				<mat-card>
					<mat-card-title>
						Important Links
					</mat-card-title>
					<mat-card-content style="height:115px; overflow: auto;" *ngIf="ImportantLinksList.length > 0">
						<div *ngFor="let linkDetail of ImportantLinksList">
							<div style="clear:right;">
								<mat-icon style="font-size: 18px;">link</mat-icon> <span style="vertical-align: super;">&nbsp;&nbsp;<a href="{{linkDetail.link_url}}" target="_blank">{{linkDetail.description}}</a></span>						
							</div>							
						</div>
					</mat-card-content>
					<mat-card-content style="height: 70px; overflow: auto;" *ngIf="ImportantLinksList.length == 0">
						No Record.
					</mat-card-content>
				</mat-card>				

			</div>
		</div>
	</div>
	<div fxFlex="35">
		<mat-card>
			<mat-card-title>
				Society Commitee Body
			</mat-card-title>
			<div class="commitee-body-section">
				<mat-card-content>				
					<mat-accordion>		  
					  <mat-expansion-panel *ngFor="let commiteeMember of CommiteeMembers" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
					    <mat-expansion-panel-header>
					      <mat-panel-title>
					        <mat-icon> person_pin</mat-icon>&nbsp; {{commiteeMember.member_name}}
					      </mat-panel-title>
					      <mat-panel-description>
					        {{ getMemberRole(commiteeMember.role) }}
					      </mat-panel-description>
					    </mat-expansion-panel-header>
					    <div class="cf">					    	
					    	<div class="member-detail-section" >
						    	<img *ngIf="!commiteeMember.profile_picture" src="../../assets/img/large-profile-icon.png" width="100" height="100">
						    	<img *ngIf="commiteeMember.profile_picture" src="{{commiteeMember.profile_picture}}" width="100" height="100">
						  	</div>
						  	<div class="member-detail">
						  		<strong>Mobile No:-</strong> {{commiteeMember.member_contact_number}}<br/><br/>
							  	<strong>Email:- </strong> {{commiteeMember.member_contact_email}}<br/><br/>
							  	<strong>Responsibilities:- </strong>{{commiteeMember.responsibilities_assigned}} 
						  	</div>						  	
					    </div>
					  </mat-expansion-panel>

					</mat-accordion>
				</mat-card-content>
			</div>
		</mat-card>		
	</div>
	
	<marquee style="background-color: turquoise; margin-top: 2px;">
		<span *ngFor="let news of News"><span class="marquee-item-right-spacing">**{{news.description}}</span></span>		
	</marquee>
</div>