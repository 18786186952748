<div fxLayout="row" fxLayout.xs="column">
	<div fxFlex="50">
		<mat-card>
			<mat-card-title>About Society</mat-card-title>
			<mat-card-content>
				<p>
					A trusted name in housing sector, Vision's Group is offering 1 BHK, 2 BHK, 3 BHK and 4 BHK Apartments in Visions Indradhanu in rapidly developing Chikali Area in PCMC. Fulfilling the dream of owning a house of one's own, it has apartments suitable for all types of pockets and caters to the requirements of all age groups.
				</p>	
				<p>
					The apartments are well-designed and well-ventilated.
					Essentials such as banks, hospitals, schools and children's park are all within a 3-kilometre radius. The beautifully landscaped property has a library, jogging track, gym as well as convenience of lifts and futuristic rainwater harvesting system.
				</p>
							
			</mat-card-content>
		</mat-card>

		<mat-card class="example-card">
		  <mat-card-header>		    
		    <mat-card-title>Garden View</mat-card-title>		    
		  </mat-card-header>
		  <img mat-card-image src="assets/images/society/banner1.jpg" alt="Photo of a Shiba Inu">
		  <mat-card-content>
		    <!-- <p>
		      The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
		      A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
		      bred for hunting.
		    </p> -->
		  </mat-card-content>
		  <!-- <mat-card-actions>
		    <button mat-button>LIKE</button>
		    <button mat-button>SHARE</button>
		  </mat-card-actions> -->
		</mat-card>

		<mat-card class="example-card">
		  <mat-card-header>		    
		    <mat-card-title>Building View Photo Gallery</mat-card-title>		    
		  </mat-card-header>
		  <img mat-card-image src="assets/images/society/banner4.jpg" alt="Photo of a Shiba Inu">
		  <mat-card-content>
		    <!-- <p>
		      The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
		      A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
		      bred for hunting.
		    </p> -->
		  </mat-card-content>
		 <!--  <mat-card-actions>
		    <button mat-button>LIKE</button>
		    <button mat-button>SHARE</button>
		  </mat-card-actions> -->
		</mat-card>

		
	</div>

	<div fxFlex="50">
		<mat-card>
			<mat-card-title>Address</mat-card-title>
			<mat-card-content>
				<p>
					<strong>Vision Indradhanu, Phase II,
					Chikali Moshi Link road,<br/>
					Jadhavwadi, Pune-411062</strong>
				</p>

				<app-google-map></app-google-map>
			</mat-card-content>
		</mat-card>

		<mat-card class="example-card">
		  <mat-card-header>		    
		    <mat-card-title>Club House</mat-card-title>		    
		  </mat-card-header>
		  <img mat-card-image src="assets/images/society/banner2.jpg" alt="Photo of a Shiba Inu">
		  <mat-card-content>
		    <!-- <p>
		      The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
		      A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
		      bred for hunting.
		    </p> -->
		  </mat-card-content>
		 <!--  <mat-card-actions>
		    <button mat-button>LIKE</button>
		    <button mat-button>SHARE</button>
		  </mat-card-actions> -->
		</mat-card>

		

		<!-- <mat-card class="example-card">
		  <mat-card-header>		    
		    <mat-card-title>Building Area</mat-card-title>		    
		  </mat-card-header>
		  <img mat-card-image src="/assets/images/society/banner3.jpg" alt="Photo of a Shiba Inu">
		  <mat-card-content>
		   
		  </mat-card-content>
		  
		</mat-card> -->
	</div>
</div>
