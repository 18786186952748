import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormsModule, NgForm } from '@angular/forms'; 
import { RestApiService } from "../shared/rest-api.service";
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  constructor(
  	public restApi: RestApiService,  	
    private fb: FormBuilder,
    private router: Router
  	) { 
  	// To initialize FormGroup  
    this.registrationForm = fb.group({  
      'username' : [null, Validators.required],  
      'email' : [null, Validators.required],
      'first_name' : [null, Validators.required],
      'last_name' : [null, Validators.required],
      'password' : [null, Validators.required],
      'confirm_password' : [null, Validators.required],
      'flat_number' : [null, Validators.required],
      'contact_number' : [null, Validators.required]
    });

  }
  
  ngOnInit(): void {
  }

  onFormSubmit(formData){
  	console.log("formData => ", formData);
    let requestDataForRegistration = {
      username: typeof(formData.value.username) != 'undefined' ? formData.value.username : '',
      email: typeof(formData.value.email) != 'undefined' ? formData.value.email : '',
      first_name: typeof(formData.value.first_name) != 'undefined' ? formData.value.first_name : '',
      last_name: typeof(formData.value.last_name) != 'undefined' ? formData.value.last_name : '',
      password: typeof(formData.value.password) != 'undefined' ? formData.value.password : '',
      password_confirm: typeof(formData.value.confirm_password) != 'undefined' ? formData.value.confirm_password : '',
      flat_number: typeof(formData.value.flat_number) != 'undefined' ? formData.value.flat_number : '',
      contact_number: typeof(formData.value.contact_number) != 'undefined' ? formData.value.contact_number : ''
    };

    return this.restApi.registerUser(JSON.stringify(requestDataForRegistration))
    .subscribe(
      data => {
        // Handle result
        if(typeof(data) != 'undefined' && typeof(data.id) != 'undefined' ){          
          Swal.fire({            
            icon: 'success',
            text: 'Account registration successfully done !',
            showConfirmButton: false,
            timer: 4000
          })

          this.router.navigate(['/login']);
        }
      },
      error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error         
        })
      }
    )}  
  

}