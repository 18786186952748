import { Component, OnInit } from '@angular/core';
import { RestApiService } from "../shared/rest-api.service";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormsModule, NgForm } from '@angular/forms'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/modal/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModel } from '../shared/modal/confirmation-dialog/confirmation-dialog';
import Swal from 'sweetalert2'
 
@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.css']
})
export class TicketDetailComponent implements OnInit {
  addCommentForm: FormGroup;
  addAttachmentForm: FormGroup;
  editTicketForm: FormGroup;
  ticketDetail: any = {};
  allTicketType: any = [];
  allTicketStatus: any = [];
  assignedToListStr: any = '';
  expenditureAmount: any = '';
  ticketAttachments: any = [];
  ticketComments: any = [];
  selectedTicketType: any = '';
  selectedTicketStatus: any = '';
  selectedTicketId: any = '';
  username = '';
 
  constructor(
  	public restApi: RestApiService,
  	private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    public dialog: MatDialog
  	) { 
    // To initialize FormGroup  
    this.addCommentForm = fb.group({  
      'comment' : [null, Validators.required]
    });

    this.addAttachmentForm = fb.group({
      ticketAttachment: ['']
    });

    this.editTicketForm = fb.group({
      'ticketDescription' : [null, Validators.required],
      'ticketType' : [null, Validators.required],
      'flatNumber' : [null, Validators.required],
      'mobileNumber' : [null, Validators.required],
      'ticketStatus' : [null, Validators.required],
      'assignedTo' : [null],
      'expenditure_amount': [null]
    });

    this.username = localStorage.getItem('username');
  }

  ngOnInit(): void {
  	this.selectedTicketId = Number(this.route.snapshot.paramMap.get('id'));
  	this.getTicketDetail(this.selectedTicketId);
    this.getAllTicketType();
    this.getAllTicketStatus();
    //this.getTicketAttachments();
  }

  onChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];      
      this.addAttachmentForm.get('ticketAttachment').setValue(file);      
    }
  }

  onSubmitFileUpload() {    
    const formData = new FormData();
    let fileAttachObj = this.addAttachmentForm.get('ticketAttachment').value;
    formData.append('file', fileAttachObj);
    formData.append('ticket', this.selectedTicketId);
    formData.append('description', typeof(fileAttachObj.name) != 'undefined' ? fileAttachObj.name : '');
    this.restApi.uploadTicketAttachment(formData).subscribe(
      (res) => {        
        this.getTicketDetail(this.selectedTicketId);
        this.addAttachmentForm.get('ticketAttachment').setValue(['']);           
        this.addAttachmentForm.reset();  
      },
      (err) => {  
        console.log(err);
      }
    );
  }

  back(): void {
    this.location.back()
  }

  getTicketDetail(id){
  	return this.restApi.getTicketsList(id).subscribe((data: {}) => {
      this.ticketDetail = data;
      let listAssignee = [];     
      for(let i=0; i < (this.ticketDetail.assigned_to.length); i++){
        listAssignee.push(this.ticketDetail.assigned_to[i].username);             
      }

      this.selectedTicketType = typeof(this.ticketDetail.ticket_type.id) != 'undefined' ? this.ticketDetail.ticket_type.id : 0;
      this.selectedTicketStatus = typeof(this.ticketDetail.ticket_status.id) != 'undefined' ? this.ticketDetail.ticket_status.id : 0;

      this.assignedToListStr = listAssignee.join(', ');
      this.ticketAttachments = this.ticketDetail.attachments;
      this.ticketComments = this.ticketDetail.comments;      
      this.expenditureAmount = this.ticketDetail.expenditure_amount;      
      console.log("this.ticketDetail => ", this.ticketDetail);
      console.log("this.ticketAttachments => ", this.ticketAttachments);
      console.log("this.ticketComments => ", this.ticketComments);

      this.editTicketForm.patchValue({
        ticketDescription: this.ticketDetail.description,
        ticketType: this.ticketDetail.ticket_type.id,
        flatNumber: this.ticketDetail.flat_number,
        mobileNumber: this.ticketDetail.mobile_number,
        ticketStatus: this.ticketDetail.ticket_status.id,
        assignedTo: this.assignedToListStr,
        expenditure_amount: this.expenditureAmount
      });
    })  
  }

  getAllTicketType(){
    return this.restApi.getTicketTypeList().subscribe((data: {}) => {
      this.allTicketType = data;
      console.log("this.allTicketType => ", this.allTicketType);
    })  
  }

  getAllTicketStatus(){
    return this.restApi.getTicketStatusList().subscribe((data: {}) => {
      this.allTicketStatus = data;
      console.log("this.allTicketStatus => ", this.allTicketStatus);
    })  
  }

  postTicketComment(commentFormData){
    console.log("commentFormData => ", commentFormData);

    let requestDataForComment = {
      comment: typeof(commentFormData.value.comment) != 'undefined' ? commentFormData.value.comment : '',
      ticket: this.selectedTicketId,
      added_by: this.username       
    };

    return this.restApi.addTicketComment(JSON.stringify(requestDataForComment)).subscribe((data: any) => {      
      if(typeof(data) != 'undefined' && typeof(data.id) != 'undefined' ){
        this.getTicketDetail(this.selectedTicketId);        
        this.addCommentForm.reset();
        Object.keys(this.addCommentForm.controls).forEach(key => {
          this.addCommentForm.controls[key].setErrors(null)
        });
      }
    })  
  }

  postEditFormSubmit(editFormData){
    console.log("postEditFormSubmit ==>", editFormData);

    let requestDataForTicket = {
      description: editFormData.value.ticketDescription,
      ticket_type: editFormData.value.ticketType,
      ticket_status: editFormData.value.ticketStatus,
      flat_number: editFormData.value.flatNumber,
      mobile_number: editFormData.value.mobileNumber,
      expenditure_amount: editFormData.value.expenditure_amount
    };

    return this.restApi.updateTicket(JSON.stringify(requestDataForTicket), this.selectedTicketId).subscribe((data: any) => {      
      if(typeof(data) != 'undefined' && typeof(data.id) != 'undefined' ){
        Swal.fire("Ticket detail updated successfully !");
      }
    })  
  }
  

  formatAddedBy(addedBy){
    return (this.username == addedBy) ? 'You': addedBy;
  }

  deleteComment(id){
    const dialogData = new ConfirmationDialogModel('Confirm', 'Are you sure you want to delete comment?');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        maxWidth: '400px',
        closeOnNavigation: true,
        data: dialogData
    })

    dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {          
          return this.restApi.deleteComment(id).subscribe((data: {}) => {      
            this.getTicketDetail(this.selectedTicketId);
          }) 
        }else{
          console.log("Keep it as it is ...");
        }
    });
    
  }

  deleteAttachment(id){
    const dialogData = new ConfirmationDialogModel('Confirm', 'Are you sure you want to delete attachment?');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        maxWidth: '400px',
        closeOnNavigation: true,
        data: dialogData
    })

    dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {          
          return this.restApi.deleteTicketAttachment(id).subscribe((data: {}) => {            
            this.getTicketDetail(this.selectedTicketId);            
          }) 
        }else{
          console.log("Keep it as it is ...");
        }
    });
  }

  openAttachment(fileUrl){
    window.open(fileUrl, "_blank");
  }

  isAdmin(){
    return false;
  }

}
