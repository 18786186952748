import { Component, OnInit } from '@angular/core';
import { RestApiService } from "../shared/rest-api.service";
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  imageObject: Array<object> = [];  

  Notice: any = [];
  News: any = [];
  CommiteeMembers: any = [];
  HelpfullInfoList: any = [];
  ImportantLinksList: any = [];
  SlidingImageList: any = [];
  panelOpenState = false;
  imageSizeObj = {
    width:'550px',
    height: '350px'
  };

  public tourSteps: TourStep[] = [
    {
      title: 'Home Page',
      selector: '.home-menu',
      content: 'Home Page provide ability to show Notices, news, commitee member list, important links.',
      orientation: Orientation.Bottom,
      useHighlightPadding: true
    },
    {
      title: 'About Society',
      selector: '.about-us-menu',
      content: 'It will show about society detail.',
      orientation: Orientation.Bottom,
      useHighlightPadding: true
    },
    {
      title: 'Complaint System',
      selector: '.ticket-menu',
      content: 'Provide ability to raise complaint, see complaint.',
      orientation: Orientation.Bottom,
      useHighlightPadding: true
    },
    {
      title: 'Gallery',
      selector: '.gallery-menu',
      content: 'User can see Gallery images here.',
      orientation: Orientation.Bottom,
      useHighlightPadding: true
    },
    {
      title: 'Registration',
      selector: '.register-menu',
      content: 'Register using this page.',
      orientation: Orientation.Bottom,
      useHighlightPadding: true
    },
    {
      title: 'Login',
      selector: '.login-menu',
      content: 'If you have already account then you can login to web portal from here.',
      orientation: Orientation.Bottom,
      useHighlightPadding: true
    }
  ];

  websiteTour: GuidedTour = {
    tourId: 'website-tour',
    steps: this.tourSteps
  };
  
  constructor(
    public restApi: RestApiService,
    public guidedTourService: GuidedTourService
  ) { }

  ngOnInit(): void {
    //this.guidedTourService.startTour(this.websiteTour);
    this.getNoticeList();
    this.getNewsList();
    this.getCommiteeMemberList();
    this.getHelpfullInfoList();
    this.getSlidingImageList();
    this.getImportantLinksList();
  }

  getNoticeList() {
    return this.restApi.getNoticeList().subscribe((data: {}) => {
      this.Notice = data;
      console.log("this.Notice ===>", this.Notice);
    })   

  }

  getNewsList() {
    return this.restApi.getNewsList().subscribe((data: {}) => {
      this.News = data;
      console.log("this.News ===>", this.News);
    })   

  }

  getCommiteeMemberList() {
    return this.restApi.getCommiteeMemberList().subscribe((data: {}) => {
      this.CommiteeMembers = data;
      console.log("this.CommiteeMembers ===>", this.CommiteeMembers);
    })   

  }

  getHelpfullInfoList() {
    return this.restApi.getHelpfullInfoList().subscribe((data: {}) => {
      this.HelpfullInfoList = data;
      console.log("this.HelpfullInfoList ===>", this.HelpfullInfoList);
    })   

  }

  getSlidingImageList() {
    return this.restApi.getSlidingImageList().subscribe((data: {}) => {
      this.SlidingImageList = data;
      console.log("this.SlidingImageList ===>", this.SlidingImageList);

      for(var i=0; i < this.SlidingImageList.length; i ++){
        console.log("imageDetail.sliding_image ==> ", this.SlidingImageList[i]);        
        this.imageObject.push({
                image: this.SlidingImageList[i].sliding_image,
                thumbImage: this.SlidingImageList[i].sliding_image,
                alt: 'alt of image',
                title: this.SlidingImageList[i].text
            });              
      }
    })   

  }

  getImportantLinksList() {
    return this.restApi.getImportantLinksList().subscribe((data: {}) => {
      this.ImportantLinksList = data;
      console.log("this.ImportantLinksList ===>", this.ImportantLinksList);
    })   

  }

  getMemberRole(roleId){
    let roleText = 'Member';
    switch(roleId){
      case 3:
        roleText = 'Chairman';
        break;
      case 2:
        roleText = "Secretory";
        break;
      case 1:
        roleText = "Tresoror";
        break;
    }

    return roleText;
  }

}
