import { Component, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit {
     // google maps zoom level
  zoom: number = 13;
  
  // initial center position for the map
  lat: number = 18.673879916308085;
  lng: number = 73.8314877834213;

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }
  
  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }
  
  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
  
  markers: marker[] = [
	  {
		  lat: 18.673879916308085,
		  lng: 73.8314877834213,
		  label: 'I',
		  draggable: true
	  }	  
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}