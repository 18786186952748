import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { RestApiService } from "../shared/rest-api.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddTicketDialogComponent } from '../add-ticket-dialog/add-ticket-dialog.component';
import { ConfirmationDialogComponent } from '../shared/modal/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogModel } from '../shared/modal/confirmation-dialog/confirmation-dialog';
import * as XLSX from 'xlsx';

export interface DialogData {
  allTicketType: any; 
}

export interface ticketElement {
  id: number;
  description: string;
  created_at: string;
  updated_at: string;
  ticket_type: number;
  ticket_status: number;
  assigned_to: any;
  flat_number: any;
  mobile_number: any;
  reported_by: any;
} 

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  displayedColumns: string[] = ['id', 'description', 'ticket_type', 'ticket_status', 'assigned_to', 'flat_number', 'mobile_number', 'reported_by', 'action'];
  dataSource: MatTableDataSource<ticketElement>;

  ticketList: any = [];
  allTicketList: any = [];
  allTicketType: any = [];
  allTicketStatus: any = [];
  username: string = '';

  constructor(
    public restApi: RestApiService,
    public dialog: MatDialog
    ) {
    this.dataSource = new MatTableDataSource([]);
    this.username = localStorage.getItem('username');
  }  

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getTicketsList();
    this.getAllTicketType();
    this.getAllTicketStatus();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddTicketDialogComponent, {      
      data: {allTicketType: this.allTicketType}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log("result ", result);
    });
  }

  preDeleteAction(id){
    const dialogData = new ConfirmationDialogModel('Confirm', 'Are you sure you want to delete?');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        maxWidth: '400px',
        closeOnNavigation: true,
        data: dialogData
    })

    dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {          
          this.deleteTicket(id);
        }else{
          console.log("Keep it as it is ...");
        }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTicketsList(){
  	return this.restApi.getTicketsList(-1).subscribe((data: {}) => {      
      this.ticketList = data;
      this.allTicketList = data;    

      this.filterTicketList('All');
    })  
  }


  getAllTicketType(){
    return this.restApi.getTicketTypeList().subscribe((data: {}) => {
      this.allTicketType = data;
      console.log("this.allTicketType => ", this.allTicketType);
    })  
  }

  getAllTicketStatus(){
    return this.restApi.getTicketStatusList().subscribe((data: {}) => {
      this.allTicketStatus = data;
      console.log("this.allTicketStatus => ", this.allTicketStatus);
    })  
  }

  deleteTicket(id){
    return this.restApi.deleteTicket(id).subscribe((data: {}) => {      
      this.getTicketsList();
    })  
  }

  filterTicketList(filterKey){
    let filteredList = [];
    let currentLoggedInUser = this.username;

    switch (filterKey) {
      case 'Reported':
        { 
          this.ticketList =  this.allTicketList.filter(function(ticketDetail) {            
            return ticketDetail.reported_by.username == currentLoggedInUser;
          });          
        }        
        break;

      case 'Assigned':
        {
          this.ticketList =  this.allTicketList.filter(function(ticketDetail) {
            var isPresent = (ticketDetail.assigned_to).find(temp=>temp.username == currentLoggedInUser)          
            return isPresent;
          }); 
        }        
        break;
      
      default:
        this.ticketList = this.allTicketList;
        break;
    }

    const tickets = Array.from({length: (this.ticketList.length)}, (_, k) => prepareTicketRecord((k), this.ticketList));
    this.dataSource = new MatTableDataSource(tickets);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'TicketList');

    /* save to file */
    XLSX.writeFile(wb, 'ticketList_'+this.username+'.xlsx');

  }

}


function prepareTicketRecord(indexNumber: number, ticketDataList: any): ticketElement {
  let ticketDetailData = ticketDataList[indexNumber];
  let assigneeStr = getListOfAssignee(ticketDetailData.assigned_to);
  assigneeStr = assigneeStr.slice(0, -1);

  return {
    id: ticketDetailData.id, 
    description: ticketDetailData.description, 
    created_at: ticketDetailData.created_at, 
    updated_at: ticketDetailData.updated_at,  
    ticket_type: ticketDetailData.ticket_type.description, 
    ticket_status: ticketDetailData.ticket_status.description, 
    assigned_to: assigneeStr,
    flat_number: ticketDetailData.flat_number,
    mobile_number: ticketDetailData.mobile_number,
    reported_by: ticketDetailData.reported_by.username
  };
}

function getListOfAssignee(assigneeListObject){
  let assignString = '';
  for(let assignee of assigneeListObject){
    assignString += assignee.username + ',';
  }

  return assignString;
}