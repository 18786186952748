import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar'; 
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';

import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import {IvyCarouselModule } from 'angular-responsive-carousel';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapComponent } from './google-map/google-map.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';
import { GalleryComponent } from './gallery/gallery.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgImageSliderModule } from 'ng-image-slider';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

// HttpClient module for RESTful API
import { HttpClientModule } from '@angular/common/http';

//import { NgxGalleryModule } from 'ngx-gallery';
import { LightboxModule } from 'ngx-lightbox';
import { LoginComponent } from './login/login.component';

import {LocationStrategy, HashLocationStrategy} from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService, AuthInterceptor, AuthGuard } from './shared/auth.service';
import { ProfileComponent } from './profile/profile.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { AddTicketDialogComponent } from './add-ticket-dialog/add-ticket-dialog.component';
import { ConfirmationDialogComponent } from './shared/modal/confirmation-dialog/confirmation-dialog.component';
import { RegistrationComponent } from './registration/registration.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { SidenavListComponent } from './sidenav-list/sidenav-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    AboutUsComponent,
    GoogleMapComponent,
    HelpdeskComponent,
    GalleryComponent,
    LoginComponent,
    ProfileComponent,
    TicketsComponent,
    TicketDetailComponent,
    AddTicketDialogComponent,
    ConfirmationDialogComponent,
    RegistrationComponent,
    VerifyUserComponent,
    SidenavListComponent
          
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,    
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    IvyCarouselModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    MatCardModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    FlexLayoutModule,
    MatExpansionModule,
    HttpClientModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonToggleModule,
    NgImageSliderModule,
    GuidedTourModule,
    AgmCoreModule.forRoot({      
      apiKey: 'AIzaSyC7o83f7atUSFfTz20nGQGb1bupKVUwYPQ'
    })
  ],
  providers: [
    AuthService,
    AuthGuard,
    GuidedTourService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
        provide: LocationStrategy, useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
