<h2 mat-dialog-title>Add Ticket</h2>
<div mat-dialog-content class="mat-typography">
	<div class="form-container">
		<form [formGroup]="addTicketForm" class="add-ticket-form" (ngSubmit)="onFormSubmit(addTicketForm)">
		  	<p> 
				<mat-form-field appearance="fill" style="width:40vw">
				  <mat-label>Ticket Description</mat-label>
				  <input matInput formControlName="ticketDescription">
				</mat-form-field>
				<mat-error>  
                    <span *ngIf="!addTicketForm.get('ticketDescription').valid && addTicketForm.get('ticketDescription').touched">Please enter ticket description !!!</span>  
                </mat-error> 
		    </p>

		    <p>
				<mat-form-field appearance="fill" style="width:40vw">
				  <mat-label>Select Type</mat-label>
				  <mat-select formControlName="ticketType">
				    <mat-option *ngFor="let ticketType of data.allTicketType" [value]="ticketType.id">{{ticketType.ticket_type}}</mat-option>
				  </mat-select>
				</mat-form-field>

				<mat-error>  
                    <span *ngIf="!addTicketForm.get('ticketType').valid && addTicketForm.get('ticketType').touched">Please select type !!!</span>  
                </mat-error>
			</p>

			<p> 
				<mat-form-field appearance="fill" style="width:40vw">
				  <mat-label>Flat Number</mat-label>
				  <input matInput formControlName="flatNumber">
				</mat-form-field>
				<mat-error>  
                    <span *ngIf="!addTicketForm.get('flatNumber').valid && addTicketForm.get('flatNumber').touched">Please enter Flat number !!!</span>  
                </mat-error> 
		    </p>

		    <p> 
				<mat-form-field appearance="fill" style="width:40vw">
				  <mat-label>Mobile Number</mat-label>
				  <input matInput formControlName="mobileNumber">
				</mat-form-field>
				<mat-error>  
                    <span *ngIf="!addTicketForm.get('mobileNumber').valid && addTicketForm.get('mobileNumber').touched">Please enter Mobile Number !!!</span>  
                </mat-error> 
		    </p>
			<p class="button-section-right">			
				<button mat-raised-button mat-primary color="primary" [disabled]="!addTicketForm.valid">Submit</button> &nbsp;
				<button mat-raised-button mat-primary color="warn" (click)="close()">Close</button>			
			</p>
		</form>
	</div>
</div>