<mat-nav-list>
  <a mat-list-item routerLink="/" (click)="onSidenavClose()">
      <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
  </a>
  <a mat-list-item routerLink="/about-us" (click)="onSidenavClose()">
      <!-- <mat-icon>home</mat-icon> --> <span class="nav-caption">About Society</span>
  </a>

  <a mat-list-item routerLink="/tickets" (click)="onSidenavClose()">
     <!--  <mat-icon>home</mat-icon> --> <span class="nav-caption">Complaints</span>
  </a>

  <a mat-list-item routerLink="/gallery" (click)="onSidenavClose()">
      <mat-icon>view_module</mat-icon> <span class="nav-caption">Gallery</span>
  </a>

  <a *ngIf="!isAuthenticated()" mat-list-item routerLink="/registration" (click)="onSidenavClose()">
      <!-- <mat-icon>view_module</mat-icon> --> <span class="nav-caption">Register</span>
  </a>

  <a *ngIf="!isAuthenticated()" mat-list-item routerLink="/login" (click)="onSidenavClose()">
      <!-- <mat-icon>view_module</mat-icon> --> <span class="nav-caption">Login</span>
  </a>

  <mat-list-item [matMenuTriggerFor]="menu" *ngIf="isAuthenticated()">
    <mat-icon>unfold_more</mat-icon>
    <a matline>{{getUserName()}}</a>
	</mat-list-item>
	<mat-menu #menu="matMenu">
	  <button routerLink="/profile" mat-menu-item (click)="onSidenavClose()">My Profile</button>
	  <button mat-menu-item (click)="logout()">Logout</button>
	</mat-menu>
  
</mat-nav-list>