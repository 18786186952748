<mat-toolbar color="primary">
	<div fxHide.gt-sm>
      <button mat-icon-button (click)="onToggleSidenav()">
          <mat-icon>menu</mat-icon>
      </button>
  	</div>
  			
	<div class="navbar-brand">
		<a class="navbar-item">
			<img src="assets/img/apple-icon-57x57.png" class="site-logo">
		</a>
	</div>

	<span class="society-name-text">&nbsp;Vision Indradhanu Society, Jadhavwadi, Chikali</span>
	
	<div class="spacebar"></div>

	<div fxShow="true" fxHide.lt-md="true">
		<a mat-button routerLink="" class="home-menu"><mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>Home</a>
		<a mat-button routerLink="about-us" class="about-us-menu">About Society</a>			
		<a *ngIf="isAuthenticated()" mat-button routerLink="tickets" class="ticket-menu">Complaints</a>
		<a *ngIf="isAuthenticated()" mat-button routerLink="gallery" class="gallery-menu"><mat-icon>view_module</mat-icon>Gallery</a>	
		
		<a mat-button *ngIf="!isAuthenticated()" routerLink="registration" class="register-menu">Register</a>
		<a mat-raised-button color="accent" *ngIf="!isAuthenticated()" routerLink="login" class="login-menu">Login</a>	
					
		
		<a *ngIf="isAuthenticated()" aria-haspopup="false" href="javascript: void(0);" role="button" aria-expanded="false" ngbdropdowntoggle="" id="profileDropdown" class="dropdown-toggle nav-link nav-user arrow-none mr-0">
			<span class="account-user-avatar" [matMenuTriggerFor]="menu">
				<img alt="user-image" class="rounded-circle" src="assets/images/default-avatar.png">
			</span>
			<span>
				<span class="account-user-name account-user-name-single">{{getUserName()}}</span>
			</span>
		</a>

		<mat-menu #menu="matMenu">
		  <a [routerLink]="['/profile']" class="dropdown-item notify-item"
            ngbDropdownItem>
            <i class="mdi mdi-account-circle mr-1"></i>
            <span>My Profile</span>
          </a>

           <a href="javascript:void(0);" class="dropdown-item notify-item" ngbDropdownItem
        (click)="logout()">
            <i class="mdi mdi-logout mr-1"></i>
            <span>Logout</span>
          </a>
		</mat-menu>			
	</div>		
	
</mat-toolbar>