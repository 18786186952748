<div fxLayout="row" fxLayoutAlign="center center">
	<div fxFlex="45">
		<mat-card>
			<mat-card-title>Please fill detail and Submit the form to contact us </mat-card-title>
			 <p>
			  <mat-form-field appearance="standard" style="width:42vw">
			    <mat-label>Name</mat-label>
			    <input matInput placeholder="ex. Santosh Naik">
			    <mat-hint>Please enter First Name and Last Name.</mat-hint>
			  </mat-form-field>
			</p>

			<p>
			  <mat-form-field appearance="standard" style="width:42vw">
			    <mat-label>Email</mat-label>
			    <input matInput placeholder="ex. test@gmail.com">
			    <mat-hint>Please enter your Email.</mat-hint>
			  </mat-form-field>			
			</p>
			
			<p>
			  <mat-form-field appearance="standard" style="width:42vw">
			    <mat-label>Phone Number</mat-label>
			    <input matInput placeholder="1234567890">
			    <mat-hint>Please enter your Phone number.</mat-hint>
			  </mat-form-field>
			</p>

			<p>
			  <mat-form-field appearance="standard" style="width:42vw">
			    <mat-label>Message</mat-label>
			    <input matInput placeholder="ex.I need share certificate">
			    <mat-hint>Please enter message you would like to communicate with Society.</mat-hint>
			  </mat-form-field>
			</p>

			<p>
				<a mat-raised-button color="accent">Submit</a>
			</p>
			 
		</mat-card>
	</div>
</div>