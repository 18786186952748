  <div class="example-header" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="90" fxFlex.lt-sm="100">
      <h2>Ticket List</h2>
      <mat-divider></mat-divider>
      <br/> 
       
      <mat-button-toggle-group appearance="legacy" name="ticketListFilter" value="All">
        <mat-button-toggle value="All" (click)="filterTicketList('All')">All</mat-button-toggle>
        <mat-button-toggle value="Reported" (click)="filterTicketList('Reported')">Reported</mat-button-toggle>
        <mat-button-toggle value="Assigned" (click)="filterTicketList('Assigned')">Assigned</mat-button-toggle>
      </mat-button-toggle-group>    

      <button mat-raised-button color="primary" class="btn-right-align" (click)="openDialog()">Add New Ticket</button>    
      <button mat-raised-button color="primary" class="btn-right-align" (click)="exportTOExcel()"><mat-icon>cloud_download</mat-icon>&nbsp;Export</button>
      
      <div fxHide.lt-sm>
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Test ticket" #input>
        </mat-form-field>

        <div id="ticketListTableContainer" class="example-container mat-elevation-z8" #TABLE>

          <mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="id"> {{element.id}} </mat-cell>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="description"> {{element.description}} </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="ticket_type">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="ticket type"> {{element.ticket_type}} </mat-cell>
            </ng-container>

            <!-- Ticket Status Column -->
            <ng-container matColumnDef="ticket_status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Status </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.ticket_status" data-label="ticket status"> {{element.ticket_status}} </mat-cell>
            </ng-container>

            <!-- Ticket Status Column -->
            <ng-container matColumnDef="assigned_to">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Assignee </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.assigned_to" data-label="Assignee"> {{element.assigned_to}} </mat-cell>
            </ng-container>

            <!-- Ticket Status Column -->
            <ng-container matColumnDef="flat_number">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Flat Number </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.flat_number" data-label="flat_number"> {{element.flat_number}} </mat-cell>
            </ng-container>

            <!-- Ticket Status Column -->
            <ng-container matColumnDef="mobile_number">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.mobile_number" data-label="mobile number"> {{element.mobile_number}} </mat-cell>
            </ng-container>

            <!-- Ticket Status Column -->
            <ng-container matColumnDef="reported_by">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Reported By </mat-header-cell>
              <mat-cell *matCellDef="let element" [style.color]="element.reported_by" data-label="Reported by"> {{element.reported_by}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Action"> <a routerLink="/tickets/{{element.id}}"> <mat-icon class='action-icon' title="Edit Ticket">edit</mat-icon></a> <mat-icon title="Delete Ticket" class='action-icon' (click)="preDeleteAction(element.id)">delete</mat-icon> </mat-cell>
              </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let element; columns: displayedColumns;">
            </mat-row>
          </mat-table>

          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>

      <div fxHide fxShow.lt-sm>
        <mat-card *ngFor="let ticket of ticketList" class="ticket-card">
          <div fxLayout="row">
            <div fxFlex="85">
              <div class="row">
                <div>              
                  {{ticket.description}}
                </div>
                <div class="status-text"> 
                  {{ticket.ticket_status.status}}
                </div>
                <div>  
                  <strong>Reported On: </strong>{{ticket.created_at | date: 'fullDate'}}                  
                </div>
                <div><strong>Modified On: </strong>{{ticket.updated_at | date: 'fullDate'}}</div>
              </div>
            </div>

            <div fxFlex="15">
              <a routerLink="/tickets/{{ticket.id}}"> <mat-icon class='action-icon' title="Edit Ticket">edit</mat-icon></a> 
              <mat-icon title="Delete Ticket" class='action-icon' (click)="preDeleteAction(ticket.id)">delete</mat-icon>
            </div>

          </div>
        </mat-card>
      </div>

    </div>

  </div>

