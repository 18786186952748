import { Component, OnInit } from '@angular/core';
import { RestApiService } from "../shared/rest-api.service";
import { FormBuilder, FormGroup, Validators, FormsModule, NgForm } from '@angular/forms'; 

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  passwordChangeForm: FormGroup;
  userDetail: any;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  side: string;


  constructor(
  	public restApi: RestApiService,
    private fb: FormBuilder
  	) { 
    this.side = 'side';

    this.profileForm = fb.group({       
      'first_name' : [null, Validators.required],
      'last_name' : [null, Validators.required],      
      'flat_number' : [null, Validators.required],
      'contact_number' : [null, Validators.required]
    });

    this.passwordChangeForm = fb.group({       
      'old_password' : [null, Validators.required],
      'new_password' : [null, Validators.required],      
      'confirm_new_password' : [null, Validators.required]
    });
  }

  ngOnInit(): void {
  	this.getUserDetail();
  }

  getUserDetail(){
  	return this.restApi.getUserProfile().subscribe((data: {}) => {   
      this.userDetail = data;   
      console.log("data => ", this.userDetail);
      this.firstName = this.userDetail.first_name;
      this.lastName = this.userDetail.last_name;
      this.username = this.userDetail.username;
      this.email = this.userDetail.email;

      this.profileForm.patchValue({
        'first_name' : this.firstName,
        'last_name' : this.lastName,      
        'flat_number' : '',
        'contact_number' : ''
      });

    })  
  }

  onProfileFormSubmit(formData){
    console.log("formData", formData); 
  }

   onFormSubmit(formData){
    console.log("formData"); 
  }

  showHideIcon(drawer){
    console.log("isOpen =>", drawer._opened);
    return drawer._opened ? 'hide-expand-icon' : 'show-expand-icon';
  }

} 
