<div fxLayout="row" fxLayoutAlign="center center">
	<div fxFlex="90">
		<mat-card *ngFor="let galleryId of galleryIdList">
			<mat-card-title> {{imagesByGalleryId[galleryId][0].gallery.gallery_title}} </mat-card-title>
			<div *ngFor="let imageDetail of imagesByGalleryId[galleryId]; let i=index" class="thumbnailDiv">
				<span>
			  		<img [src]="imageDetail.thumb" (click)="open(i, galleryId)" />
			  	</span>
			</div>
		</mat-card>
	</div>
</div>